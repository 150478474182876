import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import vueHeadful from 'vue-headful';
import VueSocialSharing from 'vue-social-sharing';
import "./css/custom-vars.scss";
import VueCarousel from 'vue-carousel';
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate);
Vue.use(VueCarousel);
Vue.use(VueSocialSharing);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('vue-headful', vueHeadful);

Vue.config.productionTip = false;

new Vue({
  router,
  components: {
  },
  render: h => h(App)
}).$mount("#app");