<template>
  <div>
    <div class="text-box">
      <div class="section grey">
        <b-container>
          <b-row>
            <b-col cols="12" md="7" lg="7" order="2">
              <div class="petition-text">
                <h1>MEHR AUSSENRAUM</h1>
                <p>Der Frühling zeigt: Zürich lebt draussen! Die Cafés und die Seepromenade sind voll und die Covid-19-Pandemie hat diesem Bedürfnis mit zwei Lockdowns eine zusätzliche Dringlichkeit verliehen. Wir fordern mehr qualitativ hochstehenden öffentlichen Aussenraum.</p>
                <h1>SIHLFELDSTRASSE</h1>
                <p>Wir fordern den Zürcher Stadtrat auf, zu prüfen, wie die Sihlfeldstrasse zwischen der Kalkbreite- und der Kanzleistrasse in den Sommermonaten Mai - September zu einer Fussgängerzone und Flaniermeile umgenutzt werden kann.</p>
                <h1>WESHALB</h1>
                <p>Dies würde die umliegenden öffentlichen Aussenräume und damit die Quartierbevölkerung vom bereits bestehenden Nutzungsdruck entlasten und ein sich draussen bewegen mit Abstand ermöglichen. Es würde auch den im letzten Jahr von der Krise hart getroffenen Unternehmen helfen, bisherige oder neue Dienstleistungen anzubieten. So erhalten die Cafés und Restaurants mehr Platz für Sitzgelegenheiten. </p>
              </div>
            </b-col>
            <b-col cols="12" md="5" lg="5" order="1" class="petition-box mb-4">
              <Petition></Petition>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="section text-center">
        <b-container>
          <div class="strassenkarte">
            <h2>WIE WIR UNS DAS VORSTELLEN</h2>
            <p>Teile der Sihlfeldstrasse werden in den Sommermonaten autofrei, Zufahrten für Anwohnende und Unternehmen werden gewährleistet.</p>
            <img class="street mt-4" src="../../src/assets/karte.jpg" alt="strassenkarte">
          </div>
        </b-container>
      </div>
      <div class="section grey text-center">
        <b-container>
          <h2 class="">WER WIR SIND</h2>
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/isabel-garcia.jpg" alt="velo">
                <div class="violet">
                  <p>Gemeinderätin <br> glp Kreis 3</p>
                  <h4>Isabel Garcia</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/nicolas.jpg" alt="velo">
                <div class="violet">
                  <p>Gemeinderat <br> glp Kreis 4&5</p>
                  <h4>Nicolas Cavalli</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/sandra.png" alt="velo">
                <div class="violet">
                  <p>Co-Präsidentin <br> glp Kreis 4&5</p>
                  <h4>Sandra Bienek</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/beat-oberholzer.jpg" alt="velo">
                <div class="violet">
                  <p>Gemeinderat <br> glp Kreis 3</p>
                  <h4>Beat Oberholzer</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/fabienne.jpg" alt="velo">
                <div class="violet">
                  <p>jglp-Kandidatin Gemeinderat <br> glp Kreis 3&9</p>
                  <h4>Fabienne Dietsche</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                <img class="icon" src="../../src/assets/marc.jpg" alt="velo">
                <div class="violet">
                  <p>Vorstandsmitglied <br> jglp Zürich & glp Stadt Zürich</p>
                  <h4>Marc Vetterli</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/christine.jpg" alt="velo">
                <div class="violet">
                  <p>Vorstandsmitglied <br> glp Kreis 3&9</p>
                  <h4>Christine Huber</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/andy.jpg" alt="velo">
                <div class="violet">
                  <p>Vorstandsmitglied <br> glp Kreis 3&9</p>
                  <h4>Andreas Kleinhans</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/thomas.jpg" alt="velo">
                <div class="violet">
                  <p>Ex Co-Präsident <br> jglp Zürich</p>
                  <h4>Thomas Hug</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/pirmin.jpg" alt="velo">
                <div class="violet">
                  <p>Alt-Gemeinderat <br> glp Kreis 3</p>
                  <h4>Pirmin Meyer</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/roy.jpg" alt="velo">
                <div class="violet">
                  <p>Gemeinderat <br> glp Kreis 4&5</p>
                  <h4>Roy Shaibal</h4>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <div class="people-card">
                  <img class="icon" src="../../src/assets/simon.jpg" alt="velo">
                <div class="violet">
                  <p>Präsident <br> glp Kreis 3&9</p>
                  <h4>Simon Affentranger</h4>
                </div>
              </div>
            </b-col>
          </b-row>
            <div class="people-card fragen">
              <p class="mt-4 px-4">Hast du Fragen oder Anmerkungen zur Petition?</p>
              <b-button class="btn btn-secondary" href="mailto:samira_eilinger@hotmail.com">Kontaktiere uns</b-button>
            </div>
        </b-container>

      </div>
      </div>
      <div class="section text-center">
        <b-container class="pb-4">
          <h2>STIMMEN ZUR PETITION</h2>
          <b-row>
            <b-col cols="12">
              <carousel :perPageCustom="[[20, 1],[1024, 2]]">
                <slide class="p-2">
                  <div class="testimonials">
                    <p class="testimonials-text">"Als Unternehmer unterstütze ich innovative Ideen - die es in der Politik braucht. Ich freue mich auf ein neues Piazza-Feeling das seinesgleichen sucht."</p>
                    <div class="mt-4 d-flex justify-content-center">
                      <img class="icon-small" src="../../src/assets/david.jpg" alt="velo">
                      <div class="text-left">
                        <p>David Amrein</p>
                        <h3>Gelateria di Berna</h3>
                      </div>
                    </div>
                  </div>
                </slide>
                <slide class="p-2">
                  <div class="testimonials">
                    <p class="testimonials-text">"Die Mobilität in der Stadt Zürich hat sich in den vergangenen Jahren stark gewandelt. Es ist an der Zeit, dass sich dieser Wandel auch im Strassenraum zeigt."</p>
                    <div class="mt-4 d-flex justify-content-center">
                      <img class="icon-small" src="../../src/assets/esther.jpg" alt="velo">
                      <div class="text-left">
                        <p>Esther Weber</p>
                        <h3>Co-Präsidentin, glp Stadt Zürich</h3>
                      </div>
                    </div>
                  </div>
                </slide>
                <slide class="p-2">
                  <div class="testimonials">
                    <p class="testimonials-text">"Dank der autofreien Sihlfeldstrasse entsteht für die Quartierbevölkerung schnell und unkompliziert mehr Freiraum. So werden die bestehenden Plätze entlastet, was auch der Quartierbevölkerung zu Gute kommt."</p>
                    <div class="mt-4 d-flex justify-content-center">
                      <img class="icon-small" src="../../src/assets/moreno.jpg" alt="velo">
                      <div class="text-left">
                        <p>Moreno Feltscher</p>
                        <h3>Anwohner Bullingerplatz</h3>
                      </div>
                    </div>
                  </div>
                </slide>
              </carousel>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="grey">
        <b-container>
          <div class="fragen py-4">
            <h2>FRAGEN UND ANTWORTEN</h2>
            <p class="q">Was ist der Unterschied zu “Brings uf d'Strass!” vom Tiefbauamt der Stadt Zürich?</p>
            <p>Die Stadt Zürich will während den Sommerferien 2021 (5 Wochen) einzelne Strassenabschnitte in den Kreisen 1, 3, 4 und 5 für den motorisierten Verkehr sperren. Das Projekt umfasst die Sihlfeldstrasse nicht. Wir fordern vom Stadtrat die Umnutzung der Sihlfeldstrasse zwischen Kalkbreite- und Kanzleistrasse in den Sommermonaten Mai-September (5 Monate) zur Fussgängerzone und Flaniermeile. </p>
            <p class="q">Was ist der Unterschied zum Eintrag einer grünen “Champs Elysées” im kommunalen Richtplan der Stadt Zürich?</p>
            <p>Im kommunalen Richtplan “Siedlung, Landschaft, öffentliche Bauten und Anlagen” (SLÖBA) konkretisiert die Stadt Zürich, welche Gebiete für die qualitätsvolle bauliche Verdichtung bis in das Jahr 2040 geeignet sind. Der Gemeinderat beschloss eine Parkanlage entlang der Sihlfeldstrasse zwischen Kalkbreitestrasse und Bullingerplatz. Die Realisierung wird Jahre, wenn nicht Jahrzehnte dauern. Wir fordern vom Stadtrat jetzt und in Zukunft die Umnutzung der Sihlfeldstrasse zwischen Kalkbreite- und Kanzleistrasse in den Sommermonaten Mai-September zur Fussgängerzone und Flaniermeile. </p>
          </div>
        </b-container>
      </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import Petition from '../components/petition';

export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
    Petition
  }
};
</script>

<style scoped>

#input-group-4 {
  color: white;
}

.text-box {
  text-align: left;
}

.testimonials {
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.testimonials-text {
  font-size: 24px;
  font-weight: 600;
}

.icon-small {
  object-fit: cover;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 30px;
  box-shadow: 0px 0px 10px #000000;
}

.people-card {
  margin: 30px;
  margin-bottom: 30px;
}
.fragen {
  margin-top: 80px;
}

.q {
  font-size: 20px;
  font-weight: 600;
}

.people-card-text {
  height: 180px;
}

.violet {
  margin-top: -20px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #8F0E57;
}

.violet p {
  color: white;
  font-weight: lighter;
}

.icon {
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  z-index: 1;
  box-shadow: 0px 0px 10px #000000;
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

.grey {
  background-color: rgba(124, 138, 95, 0.1);
}

.street {
  width: 100%;
}

.petition-box {
  margin-top: -120px;
}

.petition-text {
  margin-top: -40px;
}

@media only screen and (max-width: 768px) {
  .section {
    text-align: center;
  }

  .petition-box {
    margin-top: -200px;
  }
}

</style>