import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

// firebase init - add your own config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCwt9JQQwygIAtUV21S4R6QPSZtiU2Hcdg",
    authDomain: "sihlstrasse-19e7c.firebaseapp.com",
    projectId: "sihlstrasse-19e7c",
    storageBucket: "sihlstrasse-19e7c.appspot.com",
    messagingSenderId: "593143847947",
    appId: "1:593143847947:web:c14c3368dd2fb70899bb0e",
    measurementId: "G-4NEK1K5CN8"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig)

// utils
const db = firebaseApp.firestore()

// collection references
const usersCollection = db.collection('users')

const functions = firebase.functions()

// export utils/refs
export {
  db,
  usersCollection,
  functions
}