<template>
  <div>
    <div v-if="show">
      <div class="petition">
        <h2>Jetzt Petition unterschreiben:</h2>
        <div class="counter">
          <div class="counting" :style="style">
              
          </div>
        </div>
        <p class="counter-text mb-4">{{ count }} haben schon unterschrieben.</p>
            <b-form @submit.prevent="submit">
              <div>
                  <b-form-input
                  id="firstname"
                  v-model="user.firstname"
                  class="text-input"
                  placeholder="Vorname"
                  ></b-form-input>
              </div>
              <div>
                  <b-form-input
                  id="lastname"
                  v-model="user.lastname"
                  class="text-input"
                  placeholder="Nachname"
                  required
                  ></b-form-input>
              </div>
              <div>
                  <b-form-input
                  id="input-1"
                  v-model="user.email"
                  type="email"
                  placeholder="E-Mail"
                  required
                  ></b-form-input>
              </div>
              <div>
                <b-form-input
                id="plz"
                v-model="user.plz"
                class="number-input"
                placeholder="Postleitzahl"
                required
                ></b-form-input>
              </div>
              <div>
                  <b-form-checkbox
                    v-model="user.newsletter"
                    id="checkboxes-3" 
                    class="mt-3"
                  >Ich möchte den Newsletter der GLP Stadt Zürich erhalten.
                  </b-form-checkbox>
              </div>
              <div>
                  <b-form-checkbox 
                    name="dataterms"
                    class="mt-3" 
                    v-model="user.data"
                    id="checkboxes-4"
                    required
                    >Ich akzeptiere die <a href="https://zurich.grunliberale.ch/datenschutz.html">Datenschutzbestimmungen</a> der GLP.
                  </b-form-checkbox>
              </div>
            <b-button type="submit" class="button-primary mt-4">Absenden</b-button>
        </b-form>
      </div>
    </div>
    <div v-if="share">
      <div class="petition">
        <h2>Vielen Dank für deine Unterschrift</h2>
        <p>Teile mit deinen Freunden weshalb du die Petition unterstützt. Schick uns deine Quote und deine Funktion und wir schicken dir dein persönliches Bild per E-Mail:</p>
          <div class="d-flex justify-content-between mt-4 mb-4">
            <img class="example" src="../../src/assets/beispiel.jpg" alt="testimonial">
            <img class="example" src="../../src/assets/beispiel.jpg" alt="testimonial">
          </div>
          <b-form>
            <b-form-input
            id="function"
            v-model="user.function"
            class="text-input"
            placeholder="Funktion"
            ></b-form-input>
            <b-form-textarea
            id="lastname"
            v-model="user.quote"
            class="text-input quote"
            placeholder="Dein Quote"
            rows="5"
            max-rows="5"
            ></b-form-textarea>
          <b-button type="submit" class="button-primary" @click="update">Bild erstellen</b-button>
          </b-form>
      </div>
    </div>
    <div v-if="done">
      <div class="petition">
        <h2>Vielen Dank für deine Unterschrift.</h2>
        <p class="mb-4">Hilf mit die Sihlfeldstrasse zur Flaniermeile zu machen. Erzähle deinen Freunden von der Petition oder teile den Link auf Social Media:</p>
        <h6 class="mt-4 hashtag">#flaniermeilesihlfeld</h6>
        <h6 class="mt-4 hashtag">#glpstadtzürich</h6>
      </div>
    </div>
  </div>
</template>

<script>
import * as fb from '../firebase';
import axios from 'axios'

export default {
  name: 'Petition',
  data() {
    return {
        user: {
          firstname: "",
          lastname: "",
          plz: "",
          email: "",
          data: false,
          newsletter: false,
          function: "",
          quote: ""
        },
        show: true,
        share: false,
        done: false,
        count: 0,
        counting: 1000
    }
  },
  mounted(){
      axios
        .get("https://us-central1-sihlstrasse-19e7c.cloudfunctions.net/collectionSize")
        .then(response => (this.count = response.data))
  },
  computed: {
    style() {
      return 'width: ' + this.count / this.counting * 100 + '%';
    }
  },
    methods: {
      async submit() {
        // to form submit after this
        this.show = false
        //this.share = true
        this.done = true
        //this.$store.dispatch('createUser', this.user)
        let user=this.user;
        let userx = await fb.usersCollection.add({
          createdOn: new Date(),
          firstName: user.firstname,
          lastName: user.lastname,
          email: user.email,
          plz: user.plz,
          data: user.data,
          newsletter: user.newsletter,
          quote: "",
          function: ""
      })
      this.savedUserId = userx.id;
      },
      update() {
        this.share = false
        this.done = true
        fb.usersCollection
        .doc(this.savedUserId)
        .update(this.user)
        //this.$store.dispatch('updateUser', this.user)
      }
    }
  }
</script>


<style scoped>

.petition {
  padding: 20px;
  background-color: #8F0E57;
  color: white;
  min-height: 730px;
  text-align: left;
}

.petition h2, .petition p {
  margin-top: 0px;
  color: white;
}

.petition h2 {
    font-size: 36px;
}

.petition input {
  min-height: 50px;
  margin-top: 20px;
}

.quote {
  margin-bottom: 20px;
  margin-top: 20px;
}

.counter {
  background-color: #9AC63F;
  padding: 5px;
  color: white;
  margin-bottom: 10px;
  margin-top: 30px;
}

.counting {
  margin: 2px;
  background-color: white;
  height: 30px;
}

.counter-text {
  font-size: 1rem;
  margin-left: 5px;
}

.example {
  width: 45%;
}

.hashtag {
  font-size: 30px;
  color:#9AC63F;
}

.share {
  margin-bottom: 20px;
  width: 100%;
  border: 5px solid white;
}

.back {
  margin-top: 220px;
}
</style>
